<template>
  <Modal v-if="modal" class="SellersModal__Modal" @close="close">
    <template #header>
      <div class="SellersModal__Header">
        <span>Atualização de Seller</span>
      </div>
    </template>
    <template #body>
      <div class="SellersModal__Body">
        <p>Informação atualizada com sucesso!</p>

        <div class="SellersModal__Actions">
          <BaseButton
            label="Confirmar"
            filled
            color="dark"
            text-color="white"
            @click="close"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
import { BaseButton } from '@/components/atoms'
import { Modal } from '@/components/molecules'
export default {
  name: 'SellersModal',
  components: {
    BaseButton,
    Modal,
  },
  data() {
    return {
      id: null,
      modal: false,
    }
  },
  methods: {

    //Handlers
    open() {
      this.modal = true
      this.$emit('open')
    },

    close() {
      this.modal = false
      this.$emit('close')
    },
  }
}
</script>

<style lang="scss" scoped>
.SellersModal {
  /deep/ &__Header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    span {
      font-weight: $font-weight-bold;
    }
  }

  &__Body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;
      padding: 1.5rem 1.5rem;
    }

    &__Actions {
      display: flex;
      margin-top: 2rem;
      align-self: center;

      /deep/ .BaseButton  {
        width: 150px;
        margin: 0 10px;
      }
    }

  &__Modal {
    /deep/ .Modal__container {
      width: auto;
      height: auto;
      min-width: 300px;
    }
  }
}
</style>
