<template>
  <main class="StoresForm">
    <TheSubHeader 
      icon="store-alt"
      title="Detalhes do Seller"
      page-title-tag="Sellers Form"
    >
      <template #afterButtons>
        <BaseButton
          icon="chevron-left-alt"
          label="voltar"
          theme="icon-left"
          :track="trackSubheaderButtonString('Sellers Form', 'back')"
          @click="$router.push('/sellers')"
        />
      </template>
    </TheSubHeader>    
    <div class="StoresForm__content">
      <DynamicForm
        ref="form"
        class="StoresForm__form"
        :model="form"
        :blueprint="blueprint({hasEditPermission, sellerId, disabledFields})"
        :endpoint-errors="endpointErrors"
        submit-type="json"
        @submit="onSubmit"
      >
        <label class="StoresForm__labelphones">Telefones</label>
        <div v-if="form.phones.length" class="StoresForm__phones">
          <div v-for="(phone, i) in form.phones" :key="i" class="StoresForm__phone">
            <InputGroup
              v-model="phone.phone"
              class="StoresForm__phoneNumber"
              mask="number"
              label="Número"
              placeholder="Digite o número"
              autocomplete="no"
              :read-only="true"
            />
            <BaseCheckBox
              v-model="phone.has_whatsapp"
              name="Possui WhatsApp?"
              class="StoresForm__hasWhatsapp"
              :disabled="true"
            />
          </div>
        </div>
      </DynamicForm>
    </div>

    <!-- Sellers modal -->
    <SellersModal ref="SellersModal" />
  </main>
</template>

<script>
import { sellers } from '@/api'
import Mask from '@/plugins/mask/Mask.js'
import { mapState, mapActions } from 'vuex'
import { DynamicForm, InputGroup } from '@/components/molecules'
import { BaseCheckBox, BaseButton } from '@/components/atoms'
import { TheSubHeader } from '@/components/organisms'
import blueprint from './SellersForm.blueprint'
import SellersModal from './SellersModal.vue'

export default {
  name: 'SellersForm',
  components: {
    TheSubHeader,
    DynamicForm,
    BaseCheckBox,
    InputGroup,
    SellersModal,
    BaseButton
  },

  data() {
    return {
      form: {
        codSeller: '',
        name: '',
        cnpj: '',
        zip: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state_id: null,
        phones: [],
        flag: '',
        store: [],
        region: '',
        sequence: 0,
        vip: false,
        collect_time_range: []
      },
      endpointErrors: {},
      formBind: {
        transporter_id: null,
        transporter_code: ''
      },
      currentPage: 1,
    }
  },
  computed: {
    ...mapState({
      states: state => state.address.states,
    }),

    sellerId() {
      return this.$route.params.id
    },
    
    disabledFields() {
      return {
        isSellerVip: !this.form.vip,
        confirm: !this.form.vip
      }
    },

    hasEditPermission() {
      const userType = JSON.parse(localStorage.getItem('user'))?.roles?.[0]?.name
      return userType === 'master' || userType === 'admin'
    },
  },

  mounted() {
    if (this.sellerId) {
      this.fetchSeller()
    }
  },

  methods: {
    blueprint,
    ...mapActions({
      setLoading: 'loading/setLoading',
    }),

    async updateSeller(body) {
      const [ initial_time, end_time ] = body.collect_time_range

      const data = {
        region: body.region,
        sequence: body.sequence,
        collect_time_init: Mask.millisecondsToHour(initial_time),
        collect_time_end: Mask.millisecondsToHour(end_time),
      }

      this.setLoading(true)

      await sellers.updateSeller({ sellerCode: body.codSeller, body: data }, () => {
        this.$refs.SellersModal?.open()
      }, (e) => this.endpointErrors = e.validation, () => this.setLoading(false))
    },

    async fetchSeller() {
      this.setLoading(true)
      await sellers.fetchSeller({ id: this.sellerId }, (res) => {
        res = {
          ...res,
          ...res.documents,
          ...res.profile,
          cnpj: res.documents.cnpj == null ? '' : res.documents.cnpj,
          codSeller: res.seller_info.seller_code,
          flag: res.seller_info.is_current ? 'Offline' : 'On Demand',
          store: res.stores,
          state_id: res.state_id,
          phones: res.phones,
          company_name: res.seller_info.company_name,
          region: res.seller_info.vip_info?.region,
          sequence: res.seller_info.vip_info?.sequence,
          vip: !!(res.seller_info.vip_info?.region && res.seller_info.vip_info?.sequence),
          collect_time_range: [this.formatHour(res.seller_info.collect_time_init), this.formatHour(res.seller_info.collect_time_end)] 
        }

        if (res) this.form = res

      }, (e) => e)
      this.setLoading(false)
    },

    formatHour(hour) {
      if(!hour) {
        return
      }
      const date = new Date()
      const splitHour = hour.split(':')
      return date.setHours(splitHour[0], splitHour[1], splitHour[2])
    },

    async onSubmit(data) {
      this.updateSeller(data)
    },

  }
}
</script>

<style lang="scss" scoped>
.StoresForm {
  &__content,
  &__divider,
  &__transporters {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: 
      ". main .";
    grid-column-gap: 1rem;
  }

  &__dividerContent,
  &__transportersContent{
    grid-area: main;
  }

  &__dividerContent{
    display: flex;
    align-items: center
  }

  &__transportersContent{
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &__bind{
    display: grid;
    grid-template-columns: 3fr 2fr 1fr;
    align-items: end;
    grid-gap: 30px;
  }

  &__form {
    grid-row-gap: 2rem;
    grid-template-areas:
      "labelDetails labelDetails . . . . . . . . . ."
      "codSeller codSeller codSeller flag flag . . . . . . ."
      "store store store store store store store store store . . ."
      "name name name name company_name company_name company_name company_name company_name cnpj cnpj cnpj"
      "address address address address address address address address address address address address"
      "labelphones labelphones . . . . . . . . . ."
      "phones phones phones phones phones phones phones phones phones phones phones phones"
      "labelVip . . . . . . . . . . ."
      "vip vip vip . . . . . . . . ."
      "region region sequence . . . . . . . . ."
      "collect_time_label collect_time_label collect_time_label . . . . . . . . ."
      "collect_time_range collect_time_range collect_time_range . . . . . . . . ."
  }

  &__labelphones { grid-area: labelphones; }
  &__phones { 
    grid-area: phones;
    display: grid;
    gap: 20px 0;
    grid-template-columns: repeat(3, auto);

     @media (max-width: $viewport-sm) {
      grid-template-columns: repeat(2, auto);
    }
  }
  &__phone {
    display: flex;
    gap: 15px;
    align-items: flex-end;

    @media (max-width: $viewport-sm) {
      display: block;
    }
  }
  &__phoneNumber { 
    grid-area: phoneNumber; 
    width: 140px;  
  }
  &__hasWhatsapp { grid-area: hasWhatsapp; }

  &__divider {
    background: $color-neutral-stronger;
    min-height: 40px;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  &__label {
    color: $color-neutral-softest;
    font-weight: $font-weight-bold;
    margin-left: 20px;
    text-transform: uppercase;
    font-size: $font-size-1xmini;
  }

  &__table {
    margin: 30px 0;
  }
}
</style>