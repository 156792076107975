export default ({ hasEditPermission, sellerId, disabledFields }) => {

  const vipLabel = [
    {
      id: 'labelVip',
      tag: 'label',
      style: 'text-transform: none',
      content: 'Seller VIP'
    },
    {
      id: 'vip',
      model: 'vip',
      style: 'align-self: end',
      tag: 'BaseCheckBox',
      props: {
        name: 'Marque caso seja um Seller VIP',
      }
    },
  ]

  const vipFields = [
    {
      id: 'region',
      model: 'region',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Nome da rota',
        invalidLabel: 'Insira o nome da rota',
      }
    },
    {
      id: 'sequence',
      model: 'sequence',
      tag: 'InputGroup',
      mask: 'number',
      rules: ['required'],
      props: {
        label: 'Sequência',
        invalidLabel: 'Insira a sequência',
      }
    },
    {
      id: 'collect_time_label',
      tag: 'label',
      content: 'Janela de horário para coleta'
    },
    {
      id: 'collect_time_range',
      model: 'collect_time_range',
      tag: 'BaseDatePicker',
      rules: ['required'],
      props: {
        format: 'HH:mm:ss',
        type: 'time',
        range: true,
        clearable: false,
        placeholder: '',
      }
    },
  ]

  const submitButton = [
    {
      id: 'confirm',
      tag: 'BaseButton',
      props: {
        label: 'Salvar',
        theme: 'icon-right',
        color: 'primary-base',
        textColor: 'white',
        icon: 'save',
        type: 'submit',
        disabled: disabledFields.confirm,
        filled: true
      }
    }
  ]

  return [
    {
      id: 'labelDetails',
      tag: 'label',
      content: 'Dados Básicos'
    },

    {
      id: 'codSeller',
      model: 'codSeller',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Cód. Seller',
        invalidLabel: 'O código do seller',
        readOnly: !!sellerId,
      }
    },
    {
      id: 'store',
      model: 'store',
      tag: 'BaseTreeSelect',
      rules: ['required'],
      props: {
        name: 'Lojista',
        label: 'Lojista',
        placeholder: '',
        invalidLabel: 'Vincule ao menos um Lojista',
        multiple: true,
        tags: true,
        readOnly: !!sellerId,
        preserveHeight: true
      },
      events: {
        removeTag: 'checkDeleteStore'
      }
    },
    {
      id: 'name',
      model: 'name',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Nome',
        invalidLabel: 'Insira um nome',
        readOnly: !!sellerId,
      }
    },
    {
      id: 'company_name',
      model: 'company_name',
      tag: 'InputGroup',
      rules: ['required'],
      props: {
        label: 'Razão Social',
        invalidLabel: 'Insira a Razão Social',
        readOnly: !!sellerId,
      }
    },
    {
      id: 'cnpj',
      model: 'cnpj',
      tag: 'InputGroup',
      rules: ['cnpj'],
      props: {
        label: 'CNPJ',
        mask: 'cnpj',
        invalidLabel: 'Insira um CNPJ',
        readOnly: !!sellerId,
      }
    },
    {
      id: 'flag',
      model: 'flag',
      tag: 'InputGroup',
      props: {
        readOnly: true,
        label: 'Tipo',
        invalidLabel: 'Informe se o Seller é Fixo ou Móvel'
      }
    },
    {
      id: 'address',
      tag: 'InputAddress',
      props: {
        disabled: true,
        fields: {
          zip: 'zip',
          address: 'address',
          number: 'number',
          complement: 'complement',
          neighborhood: 'neighborhood',
          city: 'city',
          state_id: 'state_id'
        }
      }
    },

    ...(hasEditPermission ? vipLabel : []),

    ...(!disabledFields.isSellerVip ? vipFields : []),

    ...(hasEditPermission ? submitButton : [])
  ]
}